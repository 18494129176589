import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const antenneEvaluations = {
  state: {
    evaluations: [],
    evaluationContent: [],
    evaluation: null,
    objet: null
  },
  mutations: {
    setEvaluations(state, evaluations) {
      state.evaluations = evaluations
    },
    setEvaluation(state, evaluation) {
      state.evaluation = evaluation
    },
    callbackAddEvaluation(state, payload) {
      state.objet = payload.objet
    },
    callbackAddEvaluationContent(state, evaluationContent) {
      state.evaluationContent = evaluationContent
    },
    callbackEditEvaluation(state, payload) {
      state.objet = payload.objet
    }
  },
  actions: {
    //  * Récupération du content d'une évaluation (pour palier au jsonResponse vide du controleur) - à débugger absolument !
    fetchEvaluationContent(context, antenneEvaluation) {
      return ApiService.getRequest(`/antenne_evaluation_contents?antenneEvaluation=${antenneEvaluation}`).then(
        response => {
          this.commit('callbackAddEvaluationContent', response.data['hydra:member']);
        }
      )
        .catch( error => { console.error(error) } )
    },
    //  * l'évaluation est récupérée dans l'objet étudiant

    /* CRUD EVALUATIONS/TESTS (POST/PATCH/DELETE) */
    //  Ajouter une évaluation en 4 étapes
    async addEvaluation({ state, dispatch }, data) {
      try {
        //  * initialisation
        let evaluationsContent= {};
        let document = null;
        let evaluationHaveContent = false;
        let evaluationHaveDocument = false;
        const IS_ACTIVE = 0;

        //  * copie antenne_evaluation_documents dans un tableau puis suppression dans data
        document = data.antenne_evaluation_documents;
        if (document !== undefined && Object.keys(document).length > 0 && document.file !== null)
        {
          evaluationHaveDocument = true;
        }

        //  * suppression du document dans data
        delete data.antenne_evaluation_documents;

        //  * copie evaluations_content dans un tableau puis suppression dans data
        evaluationsContent = data.evaluations_content;
        if (Object.keys(data.evaluations_content).length > 0) {
          evaluationHaveContent = true;
        }

        //  * suppression du evaluations_content dans data
        delete data.evaluations_content;

        //  * étape 1: ajout infos évaluation
        const response = await ApiService.postRequest('/antenne_evaluations', data);
        if (response.status === 201) {
          let antenneEvaluationId = response.data.id;
          //  * ajout document
          if (evaluationHaveDocument) {
            document['evaluation'] = '/antenne_evaluations/' + response.data.id;
            //  * étape 2: ajout document
            await dispatch('transformDataToForm', document);
          }

          //  * ajout contenu évaluation
          if (evaluationsContent) {
            let contentPromises = [];
            for (let key in evaluationsContent) {
              if (evaluationsContent.hasOwnProperty(key)) {
                evaluationsContent[key]['antenne_evaluation'] = '/antenne_evaluations/' + response.data.id;
                evaluationsContent[key]['antenne_sequence'] = data.antenne_sequence;
                evaluationsContent[key]['etat'] = IS_ACTIVE;
                //  * étape 3: ajouts des noeuds cochés
                contentPromises.push(dispatch('transformDataContentToForm', evaluationsContent[key], response.data.id));
              }
            }
            //  * attendre que tous les enregistrements retournent une réponse
            await Promise.all(contentPromises);
          }

          //  * étape 4: ajout des noeuds manquants
          await dispatch('AddEvaluationContentParentNode', antenneEvaluationId)
          this.commit('callbackAddEvaluation', response.data)
          Notify.create({
            message: "L'évaluation a été ajoutée avec succès.",
            color: 'green'
          })
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    //  * transforme un objet en formulaire de données
    async transformDataContentToForm({ state, dispatch }, data) {
      try {
        let form = new FormData();
        form.append('antenne_evaluation', data.antenne_evaluation);
        form.append('sequence_content', data.antenne_sequence_content);
        form.append('antenne_referentiel', data.antenne_referentiel);
        if (data.unite_competence) {
          form.append('unite_competence', data.unite_competence);
        }
        if (data.competence) {
          form.append('competence', data.competence);
        }
        if (data.descripteur) {
          form.append('descripteur', data.descripteur);
        }
        form.append('type_node', data.type_node);
        form.append('label', data.label);
        if (data.score) {
          // si on ne rajoute pas || 0, le score est null dans la base de données alors que dans le form il est à 0 
          form.append('score', data.score || 0);
        }
        if (data.date_evaluation) {
          form.append('date_evaluation', data.date_evaluation);
        }
        form.append('etat', data.etat);
        if (data.commentaire) {
          form.append('commentaire', data.commentaire);
        }

        await dispatch("addEvaluationContent", form, { root: true });
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    //  * ajoute les nodes cochés dans le formulaire
    async addEvaluationContent({ state }, data) {
      return new Promise((resolve, reject) => {
        ApiService.postRequest('/antenne_evaluation_contents', data)
          .then(response => {
            this.commit('callbackAddEvaluation', response.data);
            Notify.create({
              message: "Le noeud d'évaluation a été ajouté avec succès.",
              color: 'green'
            });
            resolve();
          })
          .catch(error => {
            console.error(error);
            Notify.create({
              message: "Une erreur s'est produite",
              color: "red"
            });
            reject(error);
          });
      });
    },
    //  * ajoute les nodes manquants si non cochés puis les récupère en bdd - problème avec le retour du jsonResponse du controlleur
    async AddEvaluationContentParentNode({ state, dispatch }, uriEvaluation) {
      const response = await ApiService.postRequest('/add_antenne_evaluation_contents', uriEvaluation)
      if (response.status === 200) {
        const responseFetch = await dispatch('fetchEvaluationContent', uriEvaluation);
        this.commit('callbackAddEvaluationContent', responseFetch);
        return Promise.resolve();
      } else {
        new Error("Une erreur est survenue");
      }
    },
    async editEvaluationInformations(context, formData) {
      try {
        const response = await ApiService.patchRequest(`/antenne_evaluations/${formData.id}`, {
          evaluated_by: formData.evaluated_by,
          date_evaluation: formData.date_evaluation,
          absent: formData.absent,
          label: formData.label,
          global_score: formData.global_score,
          commentaire: formData.commentaire
        });
        if (response.status === 200) {
          this.commit('callbackEditEvaluation', response.data)
          Notify.create({
            message: "L'évaluation a été modifiée avec succès",
            color: 'green'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async statusEvaluation(context, formData) {
      try {
        const response = await ApiService.patchRequest(`${formData.id}`, { etat: formData.etat });
        if (response.status === 200) {
          this.commit('callbackEditEvaluation', response.data);
          Notify.create({
            message: formData.message,
            color: 'green'
          });
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
  },
  getters: {
    getEvaluation(state) {
      return state.evaluation;
    },
    getEvaluations(state) {
      return state.evaluations;
    }
  }
}
