import { createStore } from 'vuex'

// Modules principaux
import { auth } from './auth.module';
import { globalstore } from './global.module';
import { user } from './modules/users';
import { parametrageCfas } from "./params.cfa";

// Modules des étudiants
import { etudiant } from "./modules/etudiant";
import { etudiantPlanIndividuelFormation } from "./modules/EtudiantPlanIndividuelFormation";
import { etudiantAbsences } from "@/store/modules/etudiantAbsences";
import { etudiantPointages } from "./modules/etudiantPointages";

// Modules des antennes
import { antennePedagogieDocuments } from "./modules/antennePedagogieDocuments";
import { antenneDiplomeSessions } from "./modules/antenneDiplomeSessions";
import { antenneEvaluations } from "./modules/antenneEvaluations";
import { antenneConvention } from "./modules/antenneConvention";
import { antenneSequenceGroup} from "./modules/groupeSequences";
import { antennereferentiels } from "./modules/referentiels";
import { antenneSequence } from "./modules/antenneSequences";
import { antennediplome } from './modules/antennediplome';
import { antenneAlertes } from "./modules/alertes";

// Modules des documents
import { prestataireDocuments } from "./modules/prestataireDocuments";
import { entrepriseDocuments } from "./modules/entrepriseDocuments";
import { modeleDocuments } from "./modules/modeleDocuments";
import { documents } from "./modules/documents";

// Modules des entreprises
import { entreprises } from "./modules/entreprises";
import { entrepriseSuivis } from './modules/entrepriseSuivis';
import { entrepriseContacts } from './modules/entrepriseContacts';
import { entrepriseTaxes } from './modules/entrepriseTaxes';

// Modules de gestion des événements
import { schedulerEventEmargements } from "./modules/SchedulerEventEmargements";
import { schedulerActivities } from "./modules/schedulerActivities";
import { schedulerClassrooms } from "./modules/schedulerClassrooms.js";
import { schedulerEquipments } from "./modules/schedulerEquipments";
import { schedulerActivityModules } from "./modules/schedulerActivityModules";
import { schedulerSpeakers } from "./modules/schedulerSpeakers";
import { schedulerEvents } from "./modules/schedulerEvents";
import { schedulerOptions } from "./modules/schedulerOptions";
import { SchedulerEventEmargementEtudiants } from "./modules/SchedulerEventEmargementEtudiants";

// Autres modules
import { prestataires } from "./modules/prestataires";
import { userAlertes } from "./modules/userAlertes";
import { apiSirene } from './modules/apiSirene';

export default function (/* { ssrContext } */) {
  const store = createStore({
    modules: {
      auth,
      globalstore,
      user,
      parametrageCfas,
      etudiant,
      etudiantPlanIndividuelFormation,
      etudiantAbsences,
      etudiantPointages,
      antennePedagogieDocuments,
      antenneDiplomeSessions,
      antenneEvaluations,
      antenneConvention,
      antenneSequenceGroup,
      antennereferentiels,
      antenneSequence,
      antennediplome,
      antenneAlertes,
      prestataireDocuments,
      entrepriseDocuments,
      modeleDocuments,
      documents,
      entreprises,
      entrepriseSuivis,
      entrepriseContacts,
      entrepriseTaxes,
      schedulerEventEmargements,
      SchedulerEventEmargementEtudiants,
      schedulerActivities,
      schedulerClassrooms,
      schedulerEquipments,
      schedulerActivityModules,
      schedulerSpeakers,
      schedulerEvents,
      schedulerOptions,
      prestataires,
      userAlertes,
      apiSirene
    }
  });

  return store
}
